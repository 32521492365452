import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Swal from 'sweetalert2';
import {Logout_clear} from "../Other/Constant";
class Navbar extends Component {
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  onClickLogout() {
    Swal.fire({
      title: 'Do you want to logout?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff7600',
      cancelButtonColor: 'grey',
      confirmButtonText: 'Yes, logout'
    }).then((result) => {
      if (result.value) {
        Logout_clear();
        window.location.reload();
      }
    });
  }
  render() {
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row shadow">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/"><img style={{ width: "188px" }} src={require('../../assets/images/LogoYou.png')} alt="logo" /></Link>
          {/* <Link className="navbar-brand brand-logo" to="/"><img src={require('../../assets/images/logo.svg')} alt="logo" /></Link> */}
          <Link className="navbar-brand brand-logo-mini" to="/"><img style={{ width: "50px" }} src={require('../../assets/images/logo.png')} alt="logo" /></Link>
        </div>
        <div className="navbar-menu-wrapper d-flex pl-0">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
            <span className="mdi mdi-menu"></span>
          </button>
          <div className="search-field d-none d-md-block">
            <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                </div>
              </div>
            </form>
          </div>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
        <a className="nav-link cursor-pointer" onClick={() => { this.onClickLogout() }}>
          <button type='button' className='btn logout'>Logout</button>
        </a>
      </nav>

    );
  }
}

export default Navbar;
